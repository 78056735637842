import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { TextField, Button, Container, Typography, Box, CircularProgress, Card, CardContent, CardActions } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from 'axios';
import logo from './triteklogo.png'
import cardslogo from './cardslogo.png'

// Initialize Stripe with your publishable key
const stripePromise = loadStripe('pk_live_DcCw5bq0Njn1uwNSuvv3RlOW');

const PaymentFormDisc = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [firstPaymentAmount, setFirstPaymentAmount] = useState(274.5); // default minimum
  const [loading, setLoading] = useState(false);
  const [amountError, setAmountError] = useState('');
  const [paymentSuccess, setPaymentSuccess] = useState(false); // State for payment success
  const stripe = useStripe();
  const elements = useElements();

  const handleAmountChange = (event) => {
    const value = parseFloat(event.target.value);
    setFirstPaymentAmount(value);
    if (value < 274.5) {
      setAmountError('The first payment must be at least £274.5');
    }else {
      setAmountError('');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
  
    if (!stripe || !elements) {
      setLoading(false);
      return;
    }
  
    if (firstPaymentAmount < 274.5) {
      setAmountError('The first payment must be at least £274.5');
      setLoading(false);
      return;
    }
  
    const cardElement = elements.getElement(CardElement);
  
    try {
      // Create a Payment Method using the card element
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name,
          email
        }
      });
  
      if (error) {
        console.error(error);
        alert('Error creating payment method.');
        setLoading(false);
        return;
      }
  
      // Send the Payment Method ID to the backend
      const response = await axios.post('https://innovators.onrender.com/create-customer-disc', {
        name,
        email,
        phone,
        paymentMethodId: paymentMethod.id,
        firstPaymentAmount
      });
  
      if (response.status === 200) {
        setPaymentSuccess(true); // Set success state to true on payment completion
      } else {
        alert('Payment Failed');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('There was an error processing your payment.');
    } finally {
      setLoading(false);
    }
  };
  const today = new Date();
  const nextPaymentDate = new Date(today.setDate(today.getDate() + 29));
  
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = nextPaymentDate.toLocaleDateString(undefined, options);

  if (paymentSuccess) {
    return (
      <Container maxWidth="sm" sx={{ textAlign: 'center', mt: 4 }}>
         <img
        src={logo}
        style={{width:'200px'}}
        />
        <Card sx={{ p: 4, textAlign: 'center', backgroundColor: '#e0ffe0' }}>
          <CheckCircleIcon color="success" sx={{ fontSize: 60, mb: 2 }} />
          <CardContent>
            <Typography variant="h4" color="success.main" gutterBottom>
              Payment Successful!
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
            Thank you for your payment! Your transaction has been successfully processed.
            We will automatically charge £{549-firstPaymentAmount} on {formattedDate}
            </Typography>
          </CardContent>
        </Card>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <img
        src={logo}
        style={{width:'200px'}}
        />
        <Typography variant="h4" gutterBottom>
        Project management & Business Analysis
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Enter the amount you'd like to pay (min £274.5) in your first installment.
          We will automatically charge the next payment 29 days from your first payment
        </Typography>
      </Box>

      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <TextField
          fullWidth
          label="Name"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          sx={{ mb: 2 }}
        />

        <TextField
          fullWidth
          label="Email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          sx={{ mb: 2 }}
        />

<TextField
          fullWidth
          label="Phone Number"
          variant="outlined"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          required
          sx={{ mb: 2 }}
        />

        <TextField
          fullWidth
          label="First Payment Amount"
          variant="outlined"
          type="number"
          value={firstPaymentAmount}
          onChange={handleAmountChange}
          required
          error={!!amountError}
          helperText={amountError || 'Minimum payment amount is £274.5'}
          sx={{ mb: 2 }}
          inputProps={{ min: 274.5 }}
        />

        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Card Details
        </Typography>
        <Box sx={{ mb: 3, p: 2, border: '1px solid #ccc', borderRadius: '4px' }}>
            <div style={{display:'flex', justifyContent:'right'}}>
            <img
        src={cardslogo}
        style={{width:'120px'}}
        />
            </div>
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: '16px',
                  color: '#424770',
                  '::placeholder': {
                    color: '#aab7c4',
                  },
                },
                invalid: {
                  color: '#9e2146',
                },
              },
            }}
          />
        </Box>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!stripe || loading}
          fullWidth
        >
          {loading ? <CircularProgress size={24} /> : 'Pay Now'}
        </Button>
      </Box>
    </Container>
  );
};

const StripeWrapperUk = () => (
  <Elements stripe={stripePromise}>
    <PaymentFormDisc />
  </Elements>
);

export default StripeWrapperUk;
